<template>
  <div>
      <el-card>
        <span style="color: #009d00; font-weight: bolder;">Releasing of Documents</span><br>
        <div style="margin-bottom: 10px;">
            <el-row :gutter="20">
              <el-form style="margin-top: 30px;" @submit.native.prevent="onSearch" :model="searchForm">
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-input
                    style="width: 20%; margin-right: 10px;"
                    v-model="searchForm.term"
                    placeholder="Filter Request Code / Name / Email"
                    size="small"
                    ></el-input>

                    <el-button
                    style=" margin-right: 3px;"
                    type="primary"
                    @click="onSearch"
                    size="small"
                    :loading="loading"
                    icon="el-icon-search">
                        Search
                    </el-button>

                    <el-button
                      style=" margin-right: 10px;"
                      type="info"
                      @click="clear"
                      size="small"
                      icon="el-icon-delete"
                      :loading="loading">
                        Clear filter
                    </el-button>

                    <el-tag type="info">Total: <b>{{ meta.total }}</b></el-tag>
                    <el-button style="float: right;" @click="refresh()" type="success" size="small" :loading="loading"><i v-if="!loading" class="el-icon-refresh"></i> Refresh</el-button>
                </el-col>
                  
              </el-form>
            </el-row>
          </div>
          <!-- <el-button style="float: right;" @click="refresh()" type="success" size="small" :loading="loading"><i v-if="!loading" class="el-icon-refresh"></i> Refresh</el-button><br><br> -->

        <el-table v-if="activeTab === 'fourth'" :data="recipients" :empty-text="empty" style="width: 100%" stripe border size="mini" v-loading="loading">
          <el-table-column
            label="ID"
            fixed
            width="60px"
            >
            <template slot-scope="scope">
              <span>{{ scope.row.id }}</span>
            </template>
          </el-table-column>
          <el-table-column
            fixed
            label="Request Code">
            <template slot-scope="scope">
              <b style="font-size: 15px;">{{ scope.row.recipient_rqst_code }}</b> <span style="padding: 1px 4px 1px 4px; background-color: green; color: white;" v-if="scope.row.is_expedite">expedite</span><br>
              <div style="color:#909399; margin-top: -5px">Ready for Pick up: <br></div><div style="color:#909399;margin-top: -10px"><b>{{ scope.row.pickup_track_date }}</b> ({{ scope.row.pickup_track_time}})</div>
            </template>
            
          </el-table-column>
          <el-table-column
            label="Request(s)">

            <template slot-scope="scope">
              <div v-for="(document_requests, index) in scope.row.document_requests" :key="index">
                <span v-if="document_requests.request_type.doc_type != 'EXPEDITE_FEE' "><li>  {{ document_requests.request_type.request_name }} <span v-if="document_requests.others_request_description">: {{ document_requests.others_request_description }}</span> ({{ document_requests.no_copy }})</li></span>
              </div>
            </template>
            
          </el-table-column>
          
          <!-- <el-table-column
            prop="student_no"
            label="Student Number"
            width="150">
          </el-table-column> -->
          <el-table-column
            label="Name">
            <template slot-scope="scope">
              <b>{{ scope.row.last_name }}, {{ scope.row.first_name }} {{ scope.row.middle_name }} {{ scope.row.ext_name }}</b>
            </template>
          </el-table-column>
          <el-table-column
            label="Degree Program">
            <template slot-scope="scope">
              <span v-if="scope.row.course">{{ scope.row.course.code }} {{ !scope.row.course.major ? "" : "major in " + scope.row.course.major }}</span>
              <span v-if="!scope.row.course">No course selected</span>
            </template>
          </el-table-column>
          
          <el-table-column
            label="Total Bill"
            width="140px"
            >
            <template slot-scope="scope">
              <span>{{ formatCurrency(parseFloat(scope.row.recipient_payment.amount)) }}</span><br>
              <b>{{ scope.row.recipient_payment.payment_method ? scope.row.recipient_payment.payment_method : "Not selected yet"  }}</b><br>
              <span style="color: #909399; font-size: 10px;">OR: {{ scope.row.recipient_payment.or_number ? scope.row.recipient_payment.or_number : "---"}}</span>
            </template>
          </el-table-column>

          <el-table-column
            fixed="right"
            label="Operations"
            width="180">
            <template slot-scope="scope">
              <el-button style="width: 100%; padding: 3px; margin-bottom: 5px;" @click="handleClick(scope.row)" type="warning" size="small"><i class="el-icon-view"></i> View</el-button><br>
              <el-button style="width: 100%; padding: 3px; margin-bottom: 5px;" @click.native.prevent="verify(scope.row)" type="success" size="small"><i class="el-icon-sell"></i> Release Docs</el-button>
              <!-- <el-button style="padding: 3px" type="info" size="small">Revert</el-button>
              <el-button style="padding: 3px" type="danger" size="small">Reject</el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <div style="justify-content: center; align-items: center;display: flex;">
          <el-pagination
            class="margin-top-20"
            background
            center
            layout="prev, pager, next"
            :total="meta.total"
            :page-size="meta.per_page"
            @current-change="pageChanged"
            :current-page="meta.page"
          >
          </el-pagination>
        </div>
        
        <el-dialog title="Request Details" width="75%" :visible.sync="showDialog" :destroy-on-close.sync="destroyOnClose" v-if="showDialog">
          <view-request-component @update="refresh()" :selected="selected"></view-request-component>
        </el-dialog>

      </el-card>
  </div>
</template>

<script>
import ViewRequestComponent from '@/views/private/pages/dashboard/ViewRequestComponent.vue';
import { Recipient } from '@/api_services/recipient';
import moment from "moment";

export default {
  props: {
    activeTab: String,
  },
  name: 'Incomming',
  components: {
    ViewRequestComponent
  },
  data() {
    return {
      recipients: null,
      meta: {
        page: 1,
        pages: 1,
        total: 211,
        per_page: 15,
      },
      form:{},
      showDialog: false,
      selected: null,
      loading: false,
      destroyOnClose: true,
      empty: "No available request(s) for release in queue.",
      searchForm: {}
    };
  },
  created: function() {
    // this.getRecipients()
  },
  watch: {
    activeTab(newTab, oldTab) {
      if (newTab === 'fourth') {
        this.loading = true
        this.meta.page = 1
        this.clear();
      }
    },
  },
  methods: {
    refresh(){
      this.loading = true
      this.getRecipients();
    },
    formatCurrency(value) {
      return value.toLocaleString('en-PH', {
        style: 'currency',
        currency: 'PHP',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    verify(recipient){
      // console.log(id)
      this.form.id = recipient.id
      this.form.step = 6
      this.form.user_id = this.$store.state.savedUser.id
      let form = Object.assign({}, this.form);

      this.$prompt('Please enter the <strong style="color:#e79500;">name of the recipient or authorized representative</strong> of <strong>'+ recipient.first_name + ' ' + recipient.last_name +'</strong> who will claim the document(s) for confirmation.', {
          confirmButtonText: 'Confirm release of doc(s)',
          cancelButtonText: 'Cancel',
          dangerouslyUseHTMLString: true,
          type: 'warning'
        }).then(({ value }) => {
          
          if (!value) {
            this.$alert('Please indicate name of the person who will claim!', {
                confirmButtonText: 'OK',
            });
            return
          } else {

            this.$message({
              type: 'success',
              message: 'Document(s) successfully claimed by ' + value
            });

            form.claimed_by = value;
            Recipient.updateDetails(form)
            .then(result => {
              if (result) {
                this.$message({
                  message: "Document(s) released.",
                  type: 'success',
                  duration: 1500
                });
              } 
            })
            .catch( error => {
              console.log(error)
            })
            this.getRecipients()
          }

          
      }).catch(_ => {

      });
    },
    recipientVerifiedMsg() {
      this.$message({
        message: 'Student information verified. Notification sent through email.',
        type: 'success'
      });
    },
    getRecipients() {
      // this.meta.page = 1;
      Recipient.get({
        params: { 
            step: 6, 
            page: this.meta.page,
            term: this.searchForm.term,
          },
      })
        .then((result) => {
          this.recipients = result.data.data;
          this.recipients.forEach(function(recipient){
            recipient.created_at = moment(recipient.created_at).format("MMMM D, YYYY")

            if(recipient.date_request_verified){
              recipient.date_request_verified = moment(recipient.date_request_verified).format("MMMM D, YYYY")
            } else {
              recipient.date_request_verified = null
            }

            if(recipient.recipient_payment.payment_date){
              recipient.recipient_payment.payment_date = moment(recipient.recipient_payment.payment_date).format("MMMM D, YYYY")
            } else {
              recipient.recipient_payment.payment_date = null
            }
            
            recipient.pickup_track = recipient.request_tracks.filter(item => item.status == 6)[0];
            recipient.pickup_track_date = recipient.pickup_track ? moment(recipient.pickup_track.created_at).format("MMMM D, YYYY") : '--'
            recipient.pickup_track_time = recipient.pickup_track ? moment(recipient.pickup_track.created_at).format("hh:mm A") : '--'

          })
          // console.log(this.recipients);
          this.meta = {
            page: result.data.meta.current_page,
            total: result.data.meta.total,
            per_page: result.data.meta.per_page,
            pages: result.data.meta.last_page,
          };
          this.loading = false;
          // console.log(this.recipients);
        })
        .catch((error) => {
          this.loading = false;
          this.recipients = [];
        });
    },
    handleClick(recipient){
      this.showDialog = true
      this.selected = Object.assign({}, recipient)
    },
    pageChanged(val){
      this.meta.page = val;
      this.loading = true;
      this.getRecipients()
    },
    onSearch(){
      this.meta.page = 1;
      this.loading = true;
      this.getRecipients();
    },
    clear(){
      this.searchForm = {}
      this.meta.page = 1;
      this.loading = true;
      this.getRecipients();
    },

  }
}
</script>

<style scoped>

</style>
