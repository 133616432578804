<template>
  <div>
    <navbar-component></navbar-component>
    <div class="recipient">
      <el-card  v-loading="loading">
        
        <div v-if="verified_link">
          <div v-if="form.step <= 7">
            <el-steps simple status="success" :active="icon_step">
              <el-step :title="!smallerWidth ? 'Submit Form' : blankTitle" icon="el-icon-s-order"></el-step>
            <el-step :title="!smallerWidth ? 'Verification' : blankTitle" icon="el-icon-s-claim"></el-step>
            <el-step :title="!smallerWidth ? 'Payment' : blankTitle" icon="el-icon-upload"></el-step>
            </el-steps><hr>
          </div>

          <div v-if="form.step == 0">

            <div v-if="form.revert_remarks">
              <el-alert style="margin-top: 5px;"
                title="Document request reverted"
                type="warning"
                :closable="false"
                show-icon>
              </el-alert><br>
              <el-card shadow="none" style="background-color: #fdf6ec; border-color: #ff9600;">
                <i class="el-icon-info"></i><b> Request Form was reverted due to the following reasons:</b><br>
                <b style="color: #ff5555;">Revert reason:<i style="color: #606266"> {{ form.revert_remarks }}</i></b><br>
                <b style="color: #ff5555; font-size: small;">Note:</b> <i style="font-size: small;">Kindly update the necessary information as per the reasons provided.</i>
              </el-card>
            </div>

            <el-alert v-if="!form.revert_remarks"
              title="Request code generated."
              type="success"
              :closable="false"
              show-icon>
            </el-alert>

            <el-alert v-if="!form.revert_remarks" style="margin-top: 5px;"
              title="Kindly proceed by completing the fields below"
              description="once you've finished, click the 'Submit' button."
              type="info"
              :closable="false"
              show-icon>
            </el-alert>

            <el-alert v-if="!form.revert_remarks && form.step == 0" style="margin-top: 5px;"
              title="* Note: In order to expedite your request, please go to the University Registrar."
              description="Limit: six(6) requests per day. Once the request is verified, it can no longer be expedited."
              type="warning"
              :closable="false"
              >
            </el-alert>

            <br>
          </div>
          
          <div v-if="form.step == 1">
            <el-alert
              title="Request form submitted."
              type="success"
              :closable="false"
              show-icon>
            </el-alert><br>
            <br><br>
            <div class="bounce-image" style="text-align: center;">
              <img style="width: 20%;" :src="require('@/assets/images/search.png')" alt="verification image">
            </div>
            <div style="text-align:center;">
              <h2 style="color: #007a00; margin-top: 50px; margin-bottom: 0px;">Registrar is currently verifying your request(s).</h2>
              <span style="font-size: 25px; color: #000000; margin-top: 0px;">You may now <b>close this tab and wait for an email</b> regarding verification of your request.</span>
            </div>
          </div>

          <div v-if="form.step == 2">

            <div v-if="form.revert_remarks">
              <el-alert style="margin-top: 5px;"
                title="Document request reverted"
                type="warning"
                :closable="false"
                show-icon>
              </el-alert><br>
              <!-- <el-card shadow="none" style="background-color: #fdf6ec;">
                <b>Document request was reverted due to the following reasons:</b><br>
                <b style="color: #ff5555;">Revert reason:</b><i style="color: #606266"> {{ form.revert_remarks }}</i><br>
                <b style="color: #ff5555;">Note:</b> <i style="font-size: small;">Kindly update your attachment as per the reasons provided.</i>
              </el-card> -->
              <el-card shadow="none" style="background-color: #fdf6ec; border-color: #ff9600;">
                <i class="el-icon-info"></i><b> Document request was reverted due to the following reasons:</b><br>
                <b style="color: #ff5555;">Revert reason:<i style="color: #606266"> {{ form.revert_remarks }}</i></b><br>
                <b style="color: #ff5555; font-size: small;">Note:</b> <i style="font-size: small;">Kindly update your attachment as per the reasons provided.</i>
              </el-card>
            </div>

            <el-alert v-if="!form.revert_remarks"
              title="Student information verified."
              type="success"
              :closable="false"
              show-icon>
            </el-alert>
            <el-alert v-if="!form.revert_remarks" style="margin-top: 5px;"
              title="Select payment method and upload photo / screenshot / scanned copy of receipt."
              type="info"
              :closable="false"
              show-icon>
            </el-alert><br>

          </div>

          <div v-if="form.step == 3">
            <el-alert
              title="Payment attachment submitted."
              type="success"
              :closable="false"
              show-icon>
            </el-alert><br>
            <br><br>
            <div class="bounce-image" style="text-align: center;">
              <img style="width: 20%;" :src="require('@/assets/images/search.png')" alt="verification image">
            </div>
            <div style="text-align:center;">
              <h2 style="color: #007a00; margin-top: 50px; margin-bottom: 0px;">Registrar is currently verifying your payment.</h2>
              <span style="font-size: 25px; color: #000000; margin-top: 0px;">You may now <b>close this tab and wait for an email</b> regarding verification of your payment.</span>
            </div>
          </div>

          <div v-if="form.step > 3 && form.step <= 6">
            <el-alert
              title="Document request successfully submitted."
              type="success"
              :closable="false"
              show-icon>
            </el-alert>
            <el-alert style="margin-top: 5px;"
              title="Track you document request by clicking the button below"
              type="info"
              :closable="false"
              show-icon>
            </el-alert>
            <br>
            <el-button type="primary" style="width: 100%" @click="redirectTrack()">Track my request</el-button><br><br>
            <el-card style="border-left: solid 3px #007a00!important;" shadow="none">
              <div style="max-width: 680px;">
                <b style="color: #ff5555;">Important Note:</b><br>
                  <b style="font-size: small; color: #909399;"> 
                    If another individual would be claiming your documents on your behalf, we kindly request your 
                    representative to furnish the following requirements:

                  </b>
                <p style="font-size: small; color: #909399;">
                  1. <b>Original copy</b> of a Special Power of Attorney (SPA) or a Notarized Authorization Letter (a scanned copy may apply if the owner of the document resides in another country)<br>
                  2. <b>Original copy</b> of authorization letter (applicable only if the parent of the owner will claim the documents)<br>
                  3. <b>Photo or scanned</b> copy of your ID (back-to-back) that includes your signature.<br>
                  4. <b>Photo or scanned</b> copy of your selfie with your valid ID.<br>
                  5. <b>Photo or scanned</b> copy of the authorized person’s valid ID (back-to-back)<br>
                </p>
              </div>
            </el-card>
          </div>
          
          <div v-if="form.step == 7">
            <el-alert
              title="Document request successfully claimed."
              type="success"
              :closable="false"
              show-icon>
            </el-alert>
            <el-alert style="margin-top: 5px;"
              title="Track you document request by clicking the button below"
              type="info"
              :closable="false"
              show-icon>
            </el-alert>
            <br>
            <el-button type="primary" style="width: 100%" @click="redirectTrack()">Track my request</el-button><br><br>
            <el-card style="border-left: solid 3px #007a00!important;" shadow="none">
              <div style="max-width: 680px;">
                <div style="text-align: center;">
                  <b style="color: #007a00; "><i class="el-icon-check"></i> Documents successfully claimed.</b><br>
                </div>
                <p>We hope this efficient handling of your document(s) meets your expectations. 
                  If you have any questions or concerns regarding the claimed documents or the claim process,
                  please do not hesitate to reach out at:<br>
                </p>
                <div style="text-align: center;">
                  <b>Email: registrar@vsu.edu.ph</b><br>
                  <b>Telephone number: (053)565-0600</b> 
                </div>
              </div>
            </el-card>
          </div>

          <div v-if="form.step == 6 || form.step == 7">
            <br>
            <el-card style="border-left: solid 3px #ffcc00!important; background-color: #fffae7;" shadow="none">
              <div style="max-width: 680px;">
                <p>
                  If you havent filled out and submitted the Citizen/Client Satisfaction form:<br><br>
                  Your feedback on our services is important to us to assist you better and improve our processes and services. Please let us know your experience by clicking the button below and filing out the Citizen/Client Satisfaction form.<br><br>
                  <a class="link_button" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdWayEIeHsQwWAg45DlavFcK6mJH9eM10-zQ5vRMKkiszFOwQ/viewform?usp=pp_url&entry.147586094=Baybay&entry.1144483737=Registrar">Citizen/Client Satisfaction Form </a><br><br>
                  Thank you!
                </p>
              </div>
            </el-card>
          </div>
          

          <div style="width: 680px;" v-if="form.step == 99">
            <el-alert
              title="DOCUMENT REQUEST REJECTED"
              type="warning"
              :closable=false
              show-icon>
            </el-alert>
            <p>
              Regrettably, we are unable to fulfill your recent document request <b>
                ({{ form.recipient_rqst_code }})</b> due to the following reasons:
            </p>
            <b style="color: #ff5555;">Reject reason:</b>
            <i> {{ form.reject_remarks }}</i>
          </div>

          
          

          <el-form
            class="requestcomp-form"
            :inline="true"
            :model="form"
            :rules="rules"
            ref="form"
            @submit.native.prevent="submit('form')"
            style="max-width: 950px;">
            <div hidden>
              <el-form-item label="Recipient Request Code">
                <el-input disabled v-model="form.recipient_rqst_code"></el-input>
              </el-form-item>
              <el-form-item label="Email">
                <el-input disabled v-model="form.email"></el-input>
              </el-form-item>
            </div>
            
            

            <div v-if="form.step == 0">
              <br>
              <div class="clearfix" style="text-align:center; padding: 0px; margin-bottom: 20px;">
                <h1 style="color: #007a00; padding: 0px; margin: 0;">REQUEST FORM</h1>
              </div>
              <el-card style="border-left: solid 3px #007a00!important;" shadow="never" >
                <div v-if="form.step == 0">
                  <el-row :gutter="20" style="margin-bottom: 10px;">
                    <h3 style="color: #007a00; margin-bottom: 5px;">Requested Documents Details</h3>
                    <span style="font-size: 13px; color: #7d7d7d; "><i> (Please indicate the purpose for each requested document.)</i></span>
                  </el-row>
                  <!-- list of documented request/s list/table -->
                  <document-request-component :recipient="form" @update="setDocuments($event)" @reload="reload()" :check.sync="check"></document-request-component>                  
                </div>
                <hr>
                <el-row style="margin-top: 10px;" :gutter="20">
                  <h3 style="color: #007a00; margin-bottom: 8px;">Personal information</h3>
                  <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
                    <el-form-item label="First Name" prop="first_name">
                      <el-input v-model="form.first_name" @input="(val) => (form.first_name = form.first_name.toUpperCase())" placeholder="First name"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
                    <el-form-item label="Middle Name" prop="middle_name">
                      <el-input v-model="form.middle_name" @input="(val) => (form.middle_name = form.middle_name.toUpperCase())" placeholder="Middle Name"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
                    <el-form-item label="Last Name" prop="last_name">
                      <el-input v-model="form.last_name" @input="(val) => (form.last_name = form.last_name.toUpperCase())" placeholder="Last Name"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="2" :xl="2">
                    <el-form-item label="Extension" prop="ext_name">
                      <el-input v-model="form.ext_name" @input="(val) => (form.ext_name = form.ext_name.toUpperCase())" placeholder="Ex. Jr , III " size="large"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row :gutter="20">
                  <!-- <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
                    <el-form-item label="Birthdate" prop="birthdate">
                      <el-date-picker
                        v-model="form.birthdate"
                        type="date"
                        placeholder="Select date" 
                        value-format="yyyy-MM-dd">
                      </el-date-picker>
                    </el-form-item>
                  </el-col> -->
                  <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
                    <el-form-item label="Contact No" prop="contact_no">
                      <el-input v-model="form.contact_no" placeholder="09XXXXXXXXX" v-mask="'###########'"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
                    <el-form-item label="Sex" prop="sex">
                        <el-radio v-model="form.sex" v-for="sex in $store.state.sexes" :label="sex" :key="sex">
                          {{ sex }}
                        </el-radio>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row :gutter="20" v-if="form.sex == 'FEMALE'">
                    <h3 style="color: #007a00; margin-bottom: 8px;">Maiden name (If married)</h3>
                    <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                      <el-form-item label="First Name" prop="maiden_first_name">
                        <el-input v-model="form.maiden_first_name" @input="(val) => (form.maiden_first_name = form.maiden_first_name.toUpperCase())" placeholder="First name"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                      <el-form-item label="Middle Name" prop="maiden_middle_name">
                        <el-input v-model="form.maiden_middle_name" @input="(val) => (form.maiden_middle_name = form.maiden_middle_name.toUpperCase())" placeholder="Middle Name"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                      <el-form-item label="Last Name" prop="maiden_last_name">
                        <el-input v-model="form.maiden_last_name" @input="(val) => (form.maiden_last_name = form.maiden_last_name.toUpperCase())" placeholder="Last Name"></el-input>
                      </el-form-item>
                    </el-col>
                </el-row>
                <hr>
                <el-row :gutter="20">
                  <h3 style="color: #007a00; margin-bottom: 8px;">Other information</h3>
                  <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                    <el-form-item label="Student Number (optional)" prop="student_no">
                      <el-input v-model="form.student_no" placeholder="XX-X-XXXXX"></el-input>
                    </el-form-item>
                  </el-col>

                  <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                    <el-form-item label="Presently Enrolled in VSU?" prop="is_presently_enrolled">
                        <el-radio v-model="form.is_presently_enrolled" v-for="is_presently_enrolled in $store.state.is_presently_enrolled_choices" :label="is_presently_enrolled" :key="is_presently_enrolled">
                          {{ is_presently_enrolled }}
                        </el-radio>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row :gutter="20">
                  <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
                    <el-form-item label="Graduated from VSU?" prop="is_graduate">
                        <el-radio v-model="form.is_graduate" v-for="is_graduate in $store.state.is_graduate_choices" :label="is_graduate" :key="is_graduate">
                          {{ is_graduate }}
                        </el-radio>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4">
                    <el-form-item v-if="form.is_graduate == 'YES'" label="Year graduated" prop="year_graduated">
                      <el-date-picker
                        v-model="form.year_graduated"
                        type="year"
                        placeholder="Pick a year"
                        value-format="yyyy">
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
                
                <el-row :gutter="20">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="Degree Program" prop="course">
                      <search-course-component :course.sync="form.course" style="width:100%" ></search-course-component>
                      <span style="font-size: small; color: #838383;"><i>Type to search then select the degree program</i></span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <hr>
                <el-row>
                  <h3 style="color: #007a00; margin-bottom: 10px;">Attachments</h3>
                  <b>Upload a photo of you holding any valid ID.</b><br>
                  <b style="color: #ff5555;">Note:</b><i style="font-size: small;"> Please make sure the the photo/image uploaded is clear otherwise your request will be reverted.</i>
                  <br>
                  <br>
                  <el-upload
                    :multiple="false"
                    style="width:100%"
                    class="upload-demo"
                    ref="upload"
                    action=""
                    :limit="1"
                    :on-change="handleChange" 
                    :file-list="attachmentsID"
                    accept="image/png, image/jpeg, application/pdf"
                    :on-remove="deleteAttachment"
                    :auto-upload="false">

                    <el-button icon="el-icon-picture" slot="trigger" size="small" type="primary" v-if="attachmentsID.length < 1"> select file</el-button>

                    <div class="el-upload__tip" slot="tip">Only {{ types }} are allowed file types</div>
                  </el-upload>
                </el-row>
                <el-row>
                  <div class="attachment-container">
                    <div class="block" v-for="attachment in attachmentsID" :key="attachment.id">
                      <span class="filename">{{ attachment.filename }}</span>
                      <el-image :src="attachment.full_path">
                        <div slot="placeholder" class="image-slot">
                          Loading<span class="dot">...</span>
                        </div>
                        <div slot="error" class="image-slot">
                          <i class="el-icon-picture-outline"></i>
                        </div>
                      </el-image>
                      <el-button type="danger" icon="el-icon-delete" @click="deleteAttachment(attachment)" style="padding: 4px">Delete</el-button>
                    </div>
                    <div class="el-upload__tip" slot="tip" v-if="attachmentsID.length == 1" style="color: #838383;">You can replace the uploaded file by deleting the current attachment</div>
                  </div>
                </el-row>
              </el-card>
              
              
              <div style="text-align:center;margin-top: 20px;">
                <el-form-item>
                  <el-button
                    :loading="loading"
                    class="login-button"
                    type="success"
                    native-type="submit"
                    block
                    icon="el-icon-check"
                  >Submit</el-button>
                </el-form-item>
              </div>

              <div style="margin-top: 5px;">
                <el-alert v-if="!form.revert_remarks && form.step == 0" style="margin-top: 5px;"
                  title="* Note: In order to expedite your request, please go to the University Registrar."
                  description="Limit: six(6) requests per day. Once the request is verified, it can no longer be expedited."
                  type="warning"
                  :closable="false"
                  >
                </el-alert>
              </div>
            </div>

            <div v-if="form.step == 2">
              <div>
                <el-card shadow="none" style="background-color: #f9f9f9;">
                  Recipient Name: <b>{{ recipient.last_name }}, {{ recipient.first_name }} {{ recipient.middle_name }} {{ recipient.ext_name }}</b><br>
                  Degree Program: <b>{{ recipient.course.code }} - {{ recipient.course.description }} {{ recipient.course.major ? " major in " + recipient.course.major : "" }}</b><br>
                </el-card>
                <h3 style="color: #007a00;">Requested Document(s)</h3>
                <el-table :show-header="false" border :data="recipient.document_requests" stripe style="width: 100%;">
                    <el-table-column>
                        <template slot-scope="scope">
                            <el-row :gutter="10">
                              <el-row>
                                <el-col :xs="24" :sm="24" :md="24" :lg="20" :xl="20">
                                  <span style="font-size: 13px;"> <strong><u>{{ scope.row.request_type.request_name }} {{ scope.row.others_request_description ? ": " + scope.row.others_request_description : "" }}</u></strong></span>
                                </el-col>
                              </el-row>
                                
                                <el-row >
                                        <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10" style="color: #fff;">
                                            .
                                        </el-col>
                                        <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14" style="text-align: right;">
                                            
                                            <el-card shadow="none">
                                                <u v-if="!scope.row.fees_breakdown" style="color: #949494;">No Fees Breakdown Applied </u>
                                                <u v-if="scope.row.fees_breakdown" style="color: #949494;">Fees Breakdown </u>
                                                <div v-for="(value, key) in scope.row.fees_breakdown" :key="key" style="text-align: right;">
                                                    <el-row>
                                                        <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4" style="color: #fff;">.
                                                        </el-col>
                                                        <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
                                                            <span style="font-size: 12px;">{{ key }} - </span>
                                                        </el-col>
                                                        <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
                                                            <b style="font-size: 12px;">Php {{ formatCurrency(parseFloat(value)) }}</b>
                                                        </el-col>
                                                    </el-row>
                                                </div>
                                            </el-card>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                      <el-col :xs="24" :sm="24" :md="24" :lg="20" :xl="20" style="color: #fff;">
                                        .
                                      </el-col>
                                      <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4">
                                        <div style="text-align:right;">
                                          <span style="color: #949494; font-size: 12px;" v-if="scope.row.request_type.doc_type != 'EXPEDITE_FEE'">Copies: <strong>{{ scope.row.no_copy }}</strong></span><br>
                                          <span style="font-size: 12px;"><strong>Php {{ formatCurrency(parseFloat(scope.row.fee)) }}</strong></span>
                                        </div>
                                      </el-col>
                                    </el-row>
                                

                            </el-row>
                            
                        </template>
                    </el-table-column>
                </el-table>
              </div>
              <div>
                <el-card style="text-align:right; margin-top: 15px; border-left: solid 3px #007a00!important;">
                  <b>TOTAL: {{ form.recipient_payment.amount == null ? "" : formatCurrency(parseFloat(form.recipient_payment.amount))}}</b>
                </el-card>
              </div>
              <br>
              <el-row :gutter="20" style="margin-bottom: 10px;">
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <span style="font-size: 18px;"><strong>Select Payment Method:</strong></span><br>
                    <el-select style="width: 100%;"
                      v-model="form.recipient_payment.payment_method"
                      placeholder="Select Payment Method"
                      size="large"
                      @change="selectPaymentMethod">
                      <el-option :label="payment_method" :value="payment_method" v-for="payment_method in $store.state.payment_method" :key="payment_method"></el-option>
                    </el-select>
                </el-col>
              </el-row>

              <div style="text-align:center; margin-top: 10px; margin-bottom: 10px; max-width: 680px;">
                <b style="color: #ff5555;">Important Note:</b>
                <i  style="font-size: small; color: #505050;"> It is a payment-first basis in processing your request.
                  Your request will be processed as soon as the payment is verified.
                </i>
              </div>
              
              <el-card v-if="form.recipient_payment.payment_method" shadow="never" style="margin-bottom: 15px;">
                <div style="margin-bottom: 10px;">
                  <div v-if="form.recipient_payment.payment_method == 'VSU CASHIER'">
                      <b>For VSU CASHIER</b><span> (Payment verification: 1 working day or within the day)</span><br>
                      <span>Over the counter payment:</span><br>
                      <span>1. Present this bill or take a screenshot of this page and present it to the VSU Cashier.</span><br>
                      <span>2. Pay the total bill.</span><br>
                      <span>3. Take a picture of the receipt and upload it here.</span><br>
                    
                  </div>

                  <div v-if="form.recipient_payment.payment_method == 'LANDBANK'">
                    <b>For LANDBANK</b> <span> (Payment verification: average of 7 working day/s)</span><br>
                    <span>Name: VSU Fund 164</span> <br>
                    <span>Account Number: 3572-1000-13</span> <br>
                    <span>Bank Name: Landbank of the Philippines, Baybay Branch</span><br><br>

                    <span>Take a picture / screenshot of the receipt and upload it here.</span><br>
                  </div>

                  <div v-if="form.recipient_payment.payment_method == 'PALAWAN EXPRESS'">
                    <b>For PALAWAN EXPRESS</b> <span> (Payment verification: average of 7 working day/s)</span><br>
                    <div style="text-align: center;">
                      <img style="width: 100%;" :src="require('@/assets/images/PALAWAN PAYMENT DETAILS.jpg')" alt="palawan method image">
                    </div><br>
                    <span>Take a picture / screenshot of the receipt and upload it here.</span><br>
                  </div>
                </div>
              </el-card>
              <el-card v-if="form.recipient_payment.payment_method" shadow="never" >
                <b>Upload a photo, scanned copy or a screenshot of the receipt.</b><br>
                <b style="color: #ff5555;">Note:</b>
                <i style="font-size: small;">
                  Please make sure that the file uploaded is clear otherwise your request will be reverted.
                </i>
                
                <el-alert style="margin-top: 5px;"
                  title="Refer to the table provided above for the total amount to be paid."
                  type="info"
                  :closable="false"
                  show-icon>
                </el-alert>
                <br>

                <el-upload
                  :multiple="false"
                  style="width:100%"
                  class="upload-demo"
                  ref="upload"
                  action=""
                  :limit="1"
                  :on-change="handleChange" 
                  :file-list="attachmentsReciept"
                  accept="image/png, image/jpeg, application/pdf"
                  :on-remove="deleteAttachment"
                  :auto-upload="false">

                  <el-button icon="el-icon-picture" slot="trigger" size="large" type="primary" v-if="attachmentsReciept.length < 1">select file</el-button>

                  <div class="el-upload__tip" slot="tip">Only {{ types }} are allowed file types</div>
                </el-upload>
                <div class="attachment-container">
                  <div class="block" v-for="attachment in attachmentsReciept" :key="attachment.id">
                    <span class="filename">{{ attachment.filename }}</span>
                    <el-image :src="attachment.full_path">
                      <div slot="placeholder" class="image-slot">
                        Loading<span class="dot">...</span>
                      </div>
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                    <el-button type="danger" icon="el-icon-delete" @click="deleteAttachment(attachment)" style="padding: 4px"> Delete</el-button>
                  </div>
                  <div class="el-upload__tip" slot="tip" style="color: #838383;" v-if="attachmentsReciept.length == 1">You can replace the uploaded file by deleting the current attachment</div>
                </div>
                <div style="text-align:center;margin-top: 20px;">
                  <el-form-item>
                    <el-button
                      size="large"
                      :loading="loading"
                      class="login-button"
                      type="success"
                      native-type="submitAttachments"
                      block
                    >Submit attachment</el-button>
                  </el-form-item>
                </div>
              </el-card><br>
             
            </div>
            <br>
            <el-dialog
              title="Confirmation"
              :visible.sync="submitDialog"
              width="40%"
              :close-on-click-modal="false"
              class="responsive-dialog"
              >
              <span>You are about to submit your request form. Proceed?</span>
              <span slot="footer" class="dialog-footer">
                <el-button  @click="submitDialog = false">Back</el-button>
                <el-button type="primary" @click="confirmSubmit" :loading="loading">Yes</el-button>
              </span>
            </el-dialog>
          </el-form>
        </div>
        <div v-else>
          <el-alert
            title="INVALID LINK"
            type="warning"
            description="To continue with your request, please check your email for the correct link that has been sent to you."
            :closable=false
            show-icon>
          </el-alert>
        </div>
      
      
          

          
      </el-card>
    </div>
  </div>
</template>
  
  <script>
  
  import { Session } from '@/api_services/session';
  import { Recipient } from '@/api_services/recipient';
  import SearchCourseComponent from '@/components/common/SearchCourseComponent.vue';
  import NavbarComponent from '@/components/common/NavbarComponent.vue';
  import { Attachment } from '@/api_services/attachment';
  import DocumentRequestComponent from '@/views/form/DocumentRequestComponent.vue';
  
  export default {
    components: { SearchCourseComponent, NavbarComponent, DocumentRequestComponent },
    name: 'RecipientForm',
    props: ['check'],
    data() {
      const validateContact = (rule, value, callback) => {
        if (!value) {
          callback(new Error('Contact no is required'));
          return;
        }

        if (value.length < 11) {
          callback(new Error('Invalid Contact number'));
          return;
        }

        if (value.substr(0, 2) !== '09') {
          callback(new Error('Invalid Contact number'));
          return;
        }

        callback();
      };
      
      return {
        status: 'unload',
        item: this.course,
        courses: [],
        submitDialog: false,
        submitDialog2: false,
        form: {},
        loading: false,
        smallerWidth: false,
        rules: {
          is_presently_enrolled: [
            {required: true, message: "Please select one.", trigger: "blur"},
          ],
          first_name: [
            {required: true, message: "Please enter first name.", trigger: "blur"},
          ],
          last_name: [
            {required: true, message: "Please enter last name.", trigger: "blur"},
          ],
          sex: [
            {required: true, message: "Please select one.", trigger: "blur"},
          ],
          course: [
            {required: true, message: "Please select one.", trigger: "blur"},
          ],
          year_graduated: [
            {required: true, message: "Please select year.", trigger: "blur"},
          ],
          contact_no: [
            {required: true, trigger: 'blur', validator: validateContact },
          ],
          is_graduate: [
            {required: true, message: "Please select one.", trigger: "blur"},
          ],
        },
        verified_link: false,
        recipient: null,
        attachmentsID: [],
        attachmentsReciept: [],
        allowed_types: ['image/png', 'application/pdf', 'image/jpeg'],
        types: 'png, jpeg, pdf',
        file: null,
        icon_step: 0,
        checking: false,
        blankTitle: ''
      };
    },
    mounted() {
      // Add a listener for window resize event
      window.addEventListener("resize", this.handleResize);
      // Initial check for the window size
      this.handleResize();
    },
    created: function() {
      this.checking = true
      this.valid_documents = true
      this.loading = true
      this.verify()
      // this.addItemToCourses()
    },
    methods: {
      setDocuments(event) {
        this.form.documents = event.documents
        this.valid_documents = event.valid
      },
      handleResize() {
        // Update the smallerWidth property based on the window width
        this.smallerWidth = window.innerWidth <= 600; // Adjust the threshold as needed
      },
      async handleChange(evt) {
        this.file = evt.raw
        this.submitUpload()
      },
      formatCurrency(value) {
        return value.toLocaleString('en-PH', {
            style: 'currency',
            currency: 'PHP',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
      },
      redirectTrack(){
        this.$router.push({path: '/track', query: { recipient_rqst_code: this.recipient.recipient_rqst_code}})
      },
      toUppercase(val){
        val = val.toUpperCase()
      },
      timeOut() {
          return new Promise(resolve => {
              setTimeout(resolve, 1000);
          });
      },
      reload(){
        location.reload();
      },
      verify(){
        this.getRecipientCode()
        // console.log("here")
        // console.log(this.$route.query.recipient_token)
        // console.log(this.recipient)
        if (!this.recipient){ this.verifyToken() }
      },
      verifyToken(){
        if (!this.$route.query.recipient_token && !this.verified_link) {
          this.$prompt('<span style="font-weight: bold;font-size: 20px;color: #000000;">Please enter the Token that was sent to your email</span> <br> <div style="background-color:#fdf6ec; padding: 5px;margin-top: 10px;border-radius: 5px; line-height: 18px;"><span style="color:#E6A23C;font-size: 12px;"><strong>* Note: In order to expedite your request, please go to the University Registrar.</strong><br> Limit: six(6) requests per day. Once the request is verified, it can no longer be expedited.</span></div>', {
            confirmButtonText: 'OK',
            dangerouslyUseHTMLString: true,
            // cancelButtonText: false,
          }).then(({value}) => {
            
          const newQueryParameters = {
            email: this.$route.query.email,
            recipient_rqst_code: this.$route.query.recipient_rqst_code,
            recipient_token: value,
          };
          
            this.$router.push({ path: '/recipient-form', query: newQueryParameters });
            location.reload();
            
          }).catch(() => {
            location.reload();
          }); 
        }
      },
      getRecipientCode(){
        // console.log(this.$route.query)
        Recipient.findRecipientCode(this.$route.query)
        .then(result => {
          this.verified_link = true
          this.loading = false
          this.recipient = result.data.data

          this.icon_step = this.recipient.step
          
          if(this.recipient.step == 3){
            this.icon_step = this.recipient.step - 1
          } 

          if(this.recipient.attachment){
            this.attachmentsID = this.recipient.attachment.filter(item => item.type == 1);
            this.attachmentsReciept = this.recipient.attachment.filter(item => item.type == 2);
          } else {
            this.attachmentsID = null
            this.attachmentsReciept = null
          }
          // console.log("here")
          // console.log(this.attachmentsID)

          this.form = this.recipient
          this.form.year_graduated ? this.form.year_graduated = new Date(this.form.year_graduated + '-01-01') : this.form.year_graduated = null
          this.form.request_purpose_val
          // console.log(this.recipient)

        })
        .catch( error => {
          this.verified_link = false
          console.log(error)
          this.loading = false
          if (error.status === 401) {

            this.$message({
              message: "No record found",
              type: 'error',
              duration: 3000
            });
          }
        })
      },
      selectPaymentMethod(){
        console.log("payment", this.form.recipient_payment.payment_method);
        Recipient.savePaymentMethod(this.form)
          .then(result => {
            if (result) {
              this.recipient = result.data.data
              this.$message({
                message: "Payment Method Updated!",
                type: 'success',
                duration: 1500
              });
            } 
          })
          .catch( error => {
            console.log(error)
          })
      },
      async submit() {
        // console.log(this.form)
        const valid = await this.$refs.form.validate();
        
        if (!this.valid_documents || !valid) {
          if (this.checking) {
            this.$message({
              message: 'Complete the Purpose and review the form for required inputs.',
              type: 'error',
              duration: 3000
            });

            this.$alert('Complete the Request Purpose(s) and review the form for required inputs.', 'Please review the Documents Details', {
              type: 'error',
              confirmButtonText: 'OK',
            });
          }
          return false;
        }
        // console.log(this.form)
        let self = this
          if( !valid) {
            self.$message({
              message: "Error generating Request code",
              type: 'error',
              duration: 1500
            });
          } else {

            if(this.recipient.step == 0 ){
              if( this.attachmentsID.length == 0){
                this.$alert('Please upload a photo of you holding any valid ID.', 'Lacking required attachment', {
                  confirmButtonText: 'OK',
                });
              } else {
                this.valid = true;
                this.submitDialog = true
              }
            } else if(this.recipient.step == 2){
              if( this.attachmentsReciept.length == 0){
                this.$alert('Please upload a photo of Receipt.', 'Lacking required attachment', {
                  confirmButtonText: 'OK',
                });
              } else {
                this.valid = true;
                this.submitDialog = true
              }
            } else {
              this.valid = true;
              this.submitDialog = true
            }

            

          }
        // this.submitDialog = true
        
      },
      getFormData() {
        const formData = new FormData();
        formData.append('attachment', this.file)
        if(this.recipient.step == 0){
          formData.append("type", 1);
        } else {
          formData.append("type", 2);
        }
        

        formData.append("recipient_id", this.recipient.id);
        return formData;
      },
      confirmSubmit() {
        this.loading = true
        if (this.form.course) this.form.course_id = this.form.course.id
        if (this.form.is_graduate != "YES") this.form.year_graduated = null
        if (this.form.sex === 'MALE'){
          this.form.maiden_first_name = null 
          this.form.maiden_middle_name = null
          this.form.maiden_last_name = null
        }

        if(this.form.year_graduated){
          const year_grad = new Date(this.form.year_graduated + '-01-01');
          this.form.year_graduated = year_grad.getFullYear();
        }
        
        let form = Object.assign({}, this.form);
        // console.log(form)

        Recipient.updateDetails(form)
        .then(result => {
          // console.log(result)
          if (result) {
            this.loading = false
            this.$message({
              message: "Information has been saved.",
              type: 'success',
              duration: 1500
            });
            this.submitDialog = false
            this.verify()
          } 
        })
        .catch( error => {
          console.log(error)
          this.loading = false
        })
      },
      async submitUpload() {
        Attachment.upload(this.getFormData())
        .then(result=> {
          if(this.recipient.step == 0){
            this.attachmentsID.push(result.data.data)
          } else {
            this.attachmentsReciept.push(result.data.data)
          }       

        }).catch(error => {
          console.error(error)
          this.$message({
              message: 'Unable to upload the file. Make sure that the file size is up to 8mb only',
              type: 'error',
              duration: 5000
            }); 
        })
      },
      delete(file) {
        // console.log(file)
        let attachment = null

        if(this.recipient.step == 0) {
          attachment = this.attachmentsID.find(a => a.name === file.name);
        } else {
          attachment = this.attachmentsReciept.find(a => a.name === file.name);
        }
        

        if (attachment) {
          this.deleteAttachment(attachment);
        }
      },
      deleteAttachment(attachment) {
        Attachment.delete(attachment.id, {})
          .then(result=> {
            const idx = this.recipient.attachment.findIndex(a => a.id === attachment.id);

            if(this.recipient.step == 0){
              if (idx !== -1) {
                this.recipient.attachment.splice(idx, 1);
              }
              this.attachmentsID = this.recipient.attachment.filter(item => item.type == 1);
            } else {
              if (idx !== -1) {
                this.recipient.attachment.splice(idx, 1);
              }
              this.attachmentsReciept = this.recipient.attachment.filter(item => item.type == 2);
            }
            
            this.$message({
              message: 'Attachment removed',
              type: 'success',
              duration: 5000
            }); 
            //   this.$emit('update:attachment_update', this.attachment_update+1)
          }).catch(error => {
            console.log(error)
            this.$message({
                message: 'Unable to delete attachment',
                type: 'error',
                duration: 5000
              }); 
          })
      },
    }
  }
  </script>
  
  <style scoped>
  body {
    background-color: #003300;
  }

  @keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-30px); 
    }
  }

  .bounce-image img {
      animation: bounce 2s infinite;
  }


  .recipient {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 70px;
  }
  /* .requestcomp-form {
    width: 680px;
  } */
  .el-card__header {
      padding: 0px!important;
      border-bottom: 1px solid #EBEEF5;
      box-sizing: border-box;
      background-color: beige!important;
  }
  .attachment-container {
    margin-top: 1rem;
  }

  .block {
    height: 100px;
    width: 100px;
    background: #ccc;
    text-align: center;
    padding: 1rem;
    margin: 1rem;
    display: inline-block;
  }

  .block .filename {
    font-size: 10px;
    margin-bottom: 1rem;    
  }

  .block .el-image {
    width: 100%;
    height: 50px;
  }

  .link_button {
    color: #000000;
    background-color: #ffcc00;
    padding: 5px 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 4px;
  }
  </style>
  
  
  