import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Landing from '../views/Landing.vue'
import Request from '../views/form/InitialRequest.vue'
import TrackRequestComponent from '../views/track/TrackRequestComponent.vue'
import RecipientFormComponent from '../views/form/RecipientFormComponent.vue'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import PageNotFound from '../views/PageNotFound.vue'


import Main from '../views/private/pages/Main.vue'
import Dashboard from '../views/private/pages/dashboard/Dashboard.vue'

import Transactions from '../views/private/pages/transactions/Transactions.vue'
import Completed from '../views/private/pages/requests/Completed.vue'
import Users from '../views/private/pages/settings/users/Users.vue'
import Profile from '../views/private/pages/settings/users/Profile.vue'
import Settings from '../views/private/pages/settings/Settings.vue'
import store from '../store/index'

import Fees from '../views/private/pages/settings/conf/Fees.vue'
import ExcludedDates from '../views/private/pages/settings/excludeddates/ExcludedDates.vue'
import PortalConfiguration from '../views/private/pages/settings/portalconfig/PortalConfiguration.vue'
import Courses from '../views/private/pages/settings/courses/Courses.vue'


Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Landing',
    meta: { public: true},
    component: Landing
  },
  {
    path: '/request',
    name: 'Request',
    meta: { public: true},
    component: Request
  },
  {
    path: '/track',
    name: 'TrackRequest',
    meta: { public: true},
    component: TrackRequestComponent
  },
  {
    path: '/recipient-form',
    name: 'RecipientForm',
    meta: { public: true},
    component: RecipientFormComponent
  },
  {
    path: '/404',
    name: 'PageNotFound',
    meta: { public: true},
    component: PageNotFound
  },
  {
    path: '/login',
    name: 'Login',
    meta: { public: true},
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    meta: { public: false},
    component: Logout
  },
  {
    path: '/main',
    name: 'MainView',
    component: Main,
    meta: { public: false},
    redirect: '',
    children: [
      { path: '', component: Dashboard, name: 'main', meta: { public: false}, },
      { path: 'transactions', component: Transactions, name: 'transactions', meta: { public: false}, },
      { path: 'completed', component: Completed, name: 'completed', meta: { public: false}, },
      { path: 'settings/courses', component: Courses, name: 'courses', meta: { public: false}, },
      { path: 'settings', component: Settings, name: 'settings', meta: { public: false}, },
      { path: 'settings/users', component: Users, name: 'users', meta: { public: false}, },
      { path: 'settings/fees', component: Fees, name: 'fees', meta: { public: false}, },
      { path: 'settings/dates', component: ExcludedDates, name: 'excludeddates', meta: { public: false}, },
      { path: 'settings/configuration', component: PortalConfiguration, name: 'portalconfiguration', meta: { public: false}, },
      { path: 'profile', component: Profile, name: 'profile', meta: { public: false}, },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if ((to.name === 'settings' || to.name === 'users') && (store?.state?.savedUser as any).role !== 'admin') {
    next({name: 'main'});
    return;
  }

  next();

});

export default router
