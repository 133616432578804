import api from './api'

const API = '/api/recipients'

export const Recipient = {
    storeRecipient: function(params) {
        return api.create(API + '/store-recipient', params)
    }, 
    findRecipientCode: function(params) {
        return api.create(API + '/find-recipient-code', params)
    },
    get: function(params) {
        return api.get(API, params)
    },
    updateDetails: function(params) {
        return api.create(API + '/update-details/'+params.id, params)
    },
    updateNotes: function(params) {
        return api.create(API + '/update-notes/'+params.id, params)
    },
    updateExpedite: function(params) {
        return api.create(API + '/update-expedite/'+params.id, params)
    },
    updateOR: function(params) {
        return api.create(API + '/update-or/'+params.id, params)
    },
    savePaymentMethod: function(params) {
        return api.create(API + '/save-payment-method/'+params.id, params)
    },
    resendEmailNotif: function(params) {
        return api.create(API + '/resend-email/'+params.id, params)
    },
    // updateVerify: function(params) {
    //     return api.create(API + '/update-verify/'+params.id, params)
    // },
}
